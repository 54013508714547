import React from "react";
import { graphql } from "gatsby";
import { Button, Col, Container, Row } from "react-bootstrap";

import {
  EditableImage,
  EditableMarkdown,
  MainSponsorsSection,
  OtherEventsSection,
  SponsorsSection,
} from "@app/components";

export interface HomePageData {
  hero1_link: string;
  hero1_image: any;
  hero_body: any;
  hero2_link: string;
  hero2_image: any;
  upgrade_link: string;
  upgrade_image: any;
  charity_link: string;
  charity_image: any;
  group_body: any;
  group_button_link: string;
  group_button_text: string;
}

export const HomePage = ({
  data,
  isEdit,
}: {
  data: HomePageData;
  isEdit: boolean;
}) => {
  if (!isEdit) {
    data = (data as any).content.childMarkdownRemark.frontmatter;
  }

  return (
    <>
      {/* hero 1 */}
      {data.hero1_image && (
        <section className="d-flex justify-content-center">
          <a href={data.hero1_link}>
            <EditableImage isEdit={isEdit} image={data.hero1_image} alt="" />
          </a>
        </section>
      )}

      {/* hero body */}
      {data.hero_body && (
        <section className="text-center bg-secondary text-white py-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <EditableMarkdown isEdit={isEdit} markdown={data.hero_body} />
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {/* hero 2 */}
      {data.hero2_image && (
        <section className="d-flex justify-content-center">
          <a href={data.hero2_link}>
            <EditableImage isEdit={isEdit} image={data.hero2_image} alt="" />
          </a>
        </section>
      )}

      {/* upgrade & charity */}
      <section className="py-5 text-center">
        <Container fluid>
          <Row>
            {data.upgrade_image && (
              <Col md={6}>
                <a href={data.upgrade_link}>
                  <EditableImage
                    isEdit={isEdit}
                    image={data.upgrade_image}
                    alt=""
                  />
                </a>
              </Col>
            )}

            {data.charity_image && (
              <Col md={6}>
                <a href={data.charity_link}>
                  <EditableImage
                    isEdit={isEdit}
                    image={data.charity_image}
                    alt=""
                  />
                </a>
              </Col>
            )}
          </Row>
        </Container>
      </section>

      {/* group section */}
      <section className="text-center py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              {data.group_body && (
                <EditableMarkdown isEdit={isEdit} markdown={data.group_body} />
              )}
              {data.group_button_link && data.group_button_text && (
                <Button size="lg" href={data.group_button_link}>
                  {data.group_button_text}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* main ponsors */}
      {!isEdit && <MainSponsorsSection />}

      {/* other events */}
      {!isEdit && <OtherEventsSection />}

      {/* sponsors */}
      {!isEdit && <SponsorsSection />}
    </>
  );
};

export default HomePage;

export const query = graphql`
  query {
    content: file(
      sourceInstanceName: { eq: "content" }
      relativeDirectory: { eq: "pages" }
      name: { eq: "home" }
    ) {
      childMarkdownRemark {
        html
        frontmatter {
          seo_title
          seo_description
          hero1_image {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
          hero2_image {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
          group_body
          charity_link
          charity_image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          upgrade_link
          upgrade_image {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          group_button_link
          group_button_text
          hero1_link
          hero2_link
          hero_body
        }
      }
    }
  }
`;
